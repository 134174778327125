import { useEffect } from "react";
import React from "react";
import { getLineHeight } from "../functions/typography";

interface ManProgressProps {
    target: HTMLElement;
}

const ManProgress:React.FC<ManProgressProps> = ({target}) => {
    // const [progressText, setProgressText] = useState('');

    useEffect (() => {
        const manProgressDiv = document.querySelector('.manProgress') as HTMLElement;
        const lineHeight = getLineHeight(target);

        const updateCurrentLine = () => {
            const currentLine = Math.ceil((target.scrollTop + target.offsetHeight) / lineHeight);
            const totalLines = Math.ceil(target.scrollHeight / lineHeight);
            const progressPercentage = currentLine >= totalLines ? '(END)' : `${Math.trunc((currentLine / totalLines) * 100)}%`;
            manProgressDiv.textContent = `Manual page aaron-vranken(1) line ${currentLine > totalLines ? totalLines : currentLine}/${totalLines} ${progressPercentage}`;
        }

        let tick = false;

        // Add to cleanup.
        const eventHandler = () => window.requestAnimationFrame(updateCurrentLine);

        const throttleHandler = () => {
            if (!tick) {
                setTimeout(() => {eventHandler(); tick = false;}, 16)
            }
            tick = true;
        }

        updateCurrentLine();
        target.addEventListener('scroll', throttleHandler);
        window.addEventListener('resize', eventHandler);

        return () => {
            target.removeEventListener('scroll', throttleHandler);
            window.removeEventListener('resize', eventHandler);
        }
    }, [target])

    return (
        <React.Fragment>
            <span className='manProgress'></span>
        </React.Fragment>
    )
}

export default ManProgress;