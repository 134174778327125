import React from "react";
import './ManPage.scss';
import ManItem from "../components/ManItem";
import ManHeader from "../components/ManHeader";

const ManPage: React.FC = () => {

    return (
        <React.Fragment>
            <ManHeader command='aaron-vranken'/>
            <ManItem title='Name'>
                <span className='bold'>{'aaron-vranken'}</span>
                <span>{' - programming since 2008'}</span>
            </ManItem>    
            <ManItem title='Description'>
                <div>{'My interest in computers started when I was 13. Without telling my parents, I installed Ubuntu 8.04 on the family laptop. In the coming weeks, largely due to jumping distros multiple times, I taught myself Shell scripting.'}</div>
                <div className='marginTop'>{'During the following years I mostly stuck to scripting languages--Python and Javascript--with some basic HTML and CSS. At university, I developed an interest in webcrawlers for comparing products on ecommerce websites and graphing the price per unit for different retailers.'}</div>
                <div className='marginTop'>{'Up until this point I had mostly stuck to writing functionally, this changed when, at the end of university, I started learning C# and got more interested in OOP.'}</div>
                <div className='marginTop'>{'After graduating I continued to develop my knowledge of C# and, for my first job at a bookshop, began teaching myself SQL. I designed a database to optimise bookorders for university course materials and turned the daylong process of notifying professors and students into a single press of a button.'}</div>
                <div className='marginTop'>{'When I met my partner, a software engineer, I taught myself Swift so I could collaborate on his projects--mostly Mac and iOS apps. But, as I was more interested in cross-platform development, I also started writing apps and websites in React/React Native on the side.'}</div>
                <div className='marginTop'>{'At the moment, I am spending most of my time designing and developing apps and websites, some as personal projects, some for small businesses.'}</div>
            </ManItem>
            <ManItem title='Technical Skills'>
                <ManItem title='Operating Systems'>
                    {'Linux, macOS, Windows'}
                </ManItem>
                <ManItem title='Development Software'>
                    {'Visual Studio, Visual Studio Code, Xcode, Atom, NetBeans'}
                </ManItem>
                <ManItem title='Database Management'>
                    {'MySQL, SQLite, MS Access'}
                </ManItem>
                <ManItem title='Languages'>
                    {'Python, Swift, C#, Javascript, Typescript, Shell script, HTML, CSS, PHP, Nginx'}
                </ManItem>
                <ManItem title='Frameworks'>
                    {'SwiftUI, React, WebAPI'}
                </ManItem>
                <ManItem title='Miscellaneous'>
                    {'Nginx, Node.js, Unix System Management (bash, zsh, systemd, cron, etc.)'}
                </ManItem>
            </ManItem>
            <ManItem title='Projects'>
                <span>{'Some of the projects I have worked on in the past:'}</span>
                <ManItem title='F&V Ceramics'>
                    <div>{'A pottery website with a front-end written in React with Typescript and a back-end that consists out of:'}</div>
                    <ManItem title='Custom C# Etsy API'>
                        <div>{'A custom C# WebAPI for fetching product listings on Etsy, caching them every 10 minutes and relaying the product information to the front-end.'}</div>
                    </ManItem>
                    <ManItem title='NextJS'>
                        <div>{'Server-side rendering of the React App using NextJS to improve SEO.'}</div>
                    </ManItem>
                    <ManItem title='Strapi'>
                        <div>{'A headless CMS to provide the text content of the website and allow it to be easily modified by the client.'}</div>
                    </ManItem>
                    <div className='marginTop'>{'The entire website is hosted on a VPS with Nginx serving as a reverse proxy for the different servers.'}</div>
                </ManItem>
                <ManItem title='Course Material Database -- Peeters Bookshop'>
                    <div>{'An MS Access project to handle the communications surrounding and orders of course materials for the Catholic University of Louvain at Peeters Bookshop. This encompasses the automatic dispatching of emails to professors and students, tracking of the ordered and delivered titles, and delivering graphical representations of the sales numbers.'}</div>
                </ManItem>
                <ManItem title='Webcrawler University Professors -- Peeters Bookshop'>
                    <div>{'A Python webcrawler to automatically scrape the names and contact information of all the professors with a teaching assignment at the Catholic University of Louvain off of their website. Ordered by faculty and course programme.'}</div>
                </ManItem>
                <ManItem title='Blacklist'>
                    <div>{'A React Native app to display information about movies, actors and directors, connected to the TMDB web API.'}</div>
                </ManItem>

            </ManItem>
            <ManItem title='History'>
                <span>{'A brief history of '}</span><span className='bold'>{'aaron-vranken'}</span><span>{':'}</span>
                <ManItem title='Studies'>
                    <ManItem title='2007–2013'>
                        <div>{'Human Sciences'}</div>
                        <div>{'Humaniora Kindsheid Jesu (High School)'}</div>
                    </ManItem>
                    <ManItem title='2013-2016'>
                        <div>{'Bachelor in Philosophy'}</div>
                        <div><span className='bold'>{'Magna cum laude'}</span><span>{' - 84%'}</span></div>
                        <div>{'Vrije Universiteit Brussel'}</div>
                    </ManItem>
                    <ManItem title='2016-2017'>
                        <div>{'Master in Philosophy'}</div>
                        <div><span className='bold'>{'Summa cum laude'}</span><span>{' - 90%'}</span></div>
                        <div>{'Vrije Universiteit Brussel'}</div>
                    </ManItem>
                </ManItem>
                <ManItem title='Work'>
                    <ManItem title='April 2018-May 2020'>
                        <div>{'Administrative Assistant and Salesperson'}</div>
                        <div>{'Peeters Bookshop'}</div>
                    </ManItem>                
                </ManItem>
            </ManItem>
            <ManItem title='General Skills'>
                <ManItem title='Languages'>
                    <div>{'Dutch - Native'}</div>
                    <div>{'English - Very Good'}</div>
                    <div>{'French - Good'}</div>
                </ManItem>
            </ManItem> 
            <ManItem title='Notes'>
                <span>
                {'This man page is maintained only fitfully; the full documentation is often more up-to-date and can be requested using the following email address: '}
                </span>
                <a href = "mailto: aaron.vrank@gmail.com" className='bold inheritStyle'>{'aaron.vrank@gmail.com'}</a>
            </ManItem>      
            
        </React.Fragment>
    )
}

export default ManPage;