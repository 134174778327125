import { HTMLAttributes } from "react";
import React from "react";
import './ManItem.scss';

interface ManItemProps extends HTMLAttributes<HTMLDivElement> {
    title: string;
}

const ManItem: React.FC<ManItemProps> = ({title, children, ...rest}) => {
    return (
        <div className='manItem' {...rest}>
            <div className='manTitle'>{title.toUpperCase()}</div>
            <div className='manContent'>{children}</div>
        </div>
    )
}

export default ManItem;