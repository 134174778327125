import { useState, useEffect, SetStateAction, Dispatch } from "react";
import React from "react";
import './SplashScreen.scss';
import {ReactComponent as TerminalCursor} from '../assets/terminalcursor.svg';

interface SplashScreenProps {
    setLoaded: Dispatch<SetStateAction<boolean>>
}

const SplashScreen: React.FC<SplashScreenProps> = ({setLoaded}) => {
    const [splashText, setSplashText] = useState('');

    useEffect (() => {
        const printText = (text: string) => {
            let i = 0;
    
            const printLoop = () => {
                // sets random timeout int between 50 and 200;
                const timeout = Math.floor(Math.random() * 151) + 50;
    
                setTimeout(() => {
                    setSplashText(text.slice(0,i));
                    i++; 
                    if (i <= text.length) {
                        printLoop();
                    } else {
                        setTimeout(() => {
                            setLoaded(true);
                        }, 2500)
                    }
                }, timeout)
            }
    
            printLoop();
    
        }

        setTimeout(() => {
            printText('man aaron-vranken');
        }, 500);
    }, [setLoaded])

    return (
        <div className='splashScreen'>
            <div className='splashTextContainer'>
                <div className='splashText'>
                    {`aaron-vranken@internet:~$ ${splashText}`}
                </div>
                <TerminalCursor className='terminalCursor'/>
            </div>
        </div>
    )

}

export default SplashScreen;