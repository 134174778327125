export const setRealvh = (target: HTMLElement) => {
    const vh = window.innerHeight * 0.01;
    target.style.setProperty('--vh', `${vh}px`)
}

export const getFontSize = (target: HTMLElement) => {
    const fontSizeString = getComputedStyle(target).fontSize;
    return Number.parseInt(fontSizeString.substr(0, fontSizeString.indexOf('px')));
}

export const getLineHeight = (target: HTMLElement) => {
    const lineHeightString = getComputedStyle(target).lineHeight;
    return Number.parseInt(lineHeightString.substr(0, lineHeightString.indexOf('px')));
}

export const pixelsToRem = (pixels: number, arg: number) => {
    return pixels / arg;
}

export const remToPixels = (rem: number, fontSize: number) => {
    return rem * fontSize;
}

// Sets the height of the element to the floored rem value of the current CSS height.
export const setHeightRem = (targets: Array<HTMLElement>) => {
    for (let target of targets) {
        target.style.height = '';
    }
    for (let target of targets) {
        const height = target.offsetHeight;
        const heightRem = pixelsToRem(height, getFontSize(target));
        target.style.height = Math.floor(heightRem) + 'rem';
    }
}