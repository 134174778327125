import { ScrollAnimation, ScrollAnimationConstructor } from "../models/ScrollAnimation";
import { IScrollState, ScrollState, AnimationState } from "../models/ScrollState";
import { getLineHeight } from "../../functions/typography";

// This object works by creating a 'virtual' scroll state for an HTML element that gets updated using deltaX 
// and deltaY values. It's then possible to modify the virtual scroll state with animations and the resultant
// animation state is used to set the scroll state of the actual HTML element.

// !Note! DeltaX is yet to be implemented, it should be easy, but I haven't had the time yet.

// This corresponds to the deltaMode values of the WheelEvent specification. https://w3c.github.io/uievents/#events-wheelevents
enum DeltaMode {
    Pixel = 0,
    Line,
    Page
}

class ScrollController {
    private animations = new Array<ScrollAnimation | null>();
    private scrollState: IScrollState;
    private animationState: AnimationState;
    private fpsInMs?: number;
    // What type of wheel event is supported by the browser? Is 'wheel' if onwheel event is available, otherwise defaults to 'mousewheel'.
    private wheelEvent: string;
    // lineHeight of the current element. Only checked in the constructor.
    private lineHeight: number;

    private touch = false;
    private deltaWasSet = false;
    private animationIsStopped = true;

    // Flag for the onScroll function.
    // This is necessary because setting a HTML element's scrollTop or scrollLeft calls the scroll event.
    // private ignoreScroll = false;

    // Used by the touchMove and onScroll functions to calculate the deltaY.
    private previous = {
        // Desktop
        scrollTop: 0,
        scrollLeft: 0,
        // Mobile
        touchY: 0,
        touchX: 0,
    }

    constructor(target: HTMLElement | string) {
        const tempTarget = typeof(target) === 'string' ? document.querySelector(target) : target;

        if (tempTarget) {
            this.scrollState = new ScrollState({
                target: tempTarget as HTMLElement,
                deltaY: 0,
                scrollTop: tempTarget.scrollTop,
                scrollLeft: tempTarget.scrollLeft
            })

            this.animationState = new AnimationState(this.scrollState);

            this.wheelEvent = 'onwheel' in tempTarget ? 'wheel' : 'mousewheel';

            this.lineHeight = getLineHeight(tempTarget as HTMLElement);
        } else {
            throw new Error('ScrollController: HTML target element not found.')
        }

        this.requestAnimationFrame = this.requestAnimationFrameUnthrottled;
    }

    public start() {
        this.scrollState.target.addEventListener(this.wheelEvent, this.scrollStartHandler);
        this.scrollState.target.addEventListener('touchstart', this.scrollStartHandler);
        this.scrollState.target.addEventListener('scroll', this.scrollStartHandler);
    }

    public stop() {
        this.scrollState.deltaY = 0;
        this.scrollState.target.removeEventListener(this.wheelEvent, this.scrollStartHandler);
        this.scrollState.target.removeEventListener('touchstart', this.scrollStartHandler);
        this.scrollState.target.removeEventListener('scroll', this.scrollStartHandler);
    }

    public addAnimation(constructor: ScrollAnimationConstructor): ScrollController {
        const animation = new constructor(this.scrollState.target);
        this.animations.push(animation);
        return this;
    }

    public resetAnimations() {
        this.animationCleanup();
        this.animations = new Array<ScrollAnimation>();
    }

    private animationCleanup() {
        for (let animation of this.animations) {
            if (animation?.cleanup) {
                animation.cleanup();
            }
            animation = null;
        }
    }

    // Sets the fps and turns on throttling if a number is given. Turns off throttling if it isn't.
    public setFps(fps?: number): ScrollController {
        if (fps !== undefined) {
            this.fpsInMs = 1000/fps;
            this.requestAnimationFrame = this.requestAnimationFrameThrottled;
        } else {
            this.requestAnimationFrame = this.requestAnimationFrameUnthrottled;
        }
        return this;
    }

    private requestAnimationFrame: () => void;
    private readonly requestAnimationFrameThrottled = () => setTimeout(this.requestAnimationFrameUnthrottled, this.fpsInMs);
    private readonly requestAnimationFrameUnthrottled = () => window.requestAnimationFrame(this.updateScrollCallback);
    private readonly updateScrollCallback = this.updateScroll.bind(this);

    // updateScroll checks if any delta values have been set (deltaWasSet). If so, it updates the HTML element
    // scroll position based on ScrollAnimations, continues requestAnimation loop and sets the deltaWasSet to false. 
    // If deltaWasSet is false when updateScroll is called, it means that no eventhandlers has set a new delta value 
    // and the loop can be stopped, unless the scrolling is touch based and a finger is still touching the screen.
    private updateScroll() {
        if (this.deltaWasSet) {

            // Sets the animationState to the current scrollState.
            this.animationState.setTo(this.scrollState);

            for (let animation of this.animations) {
                animation?.onUpdate(this.animationState);
            }

            this.previous.scrollTop = this.animationState.scrollTop;
            this.previous.scrollLeft = this.animationState.scrollLeft;
            
            // Set the new scroll values to the HTML element.
            this.scrollState.target.scrollTop = this.animationState.scrollTop;
            this.scrollState.target.scrollLeft = this.animationState.scrollLeft;

            this.requestAnimationFrame();
        } else if (this.touch) {
            this.requestAnimationFrame();
        } else {
            this.animationIsStopped = true;
        }

        this.deltaWasSet = false;
    }

    private startAnimationLoop() {
        if (this.animationIsStopped) {
            this.requestAnimationFrame();
            this.animationIsStopped = false;
        }
    }

    private readonly scrollStartHandler = this.scrollStart.bind(this);
    private scrollStart(event: Event) {
        if (event.type.includes('touch')) {
            this.touchStart(event as TouchEvent);
        } else if (event.type === 'wheel') { 
            this.onWheel(event as WheelEvent)
        } else if (event.type === 'mousewheel' || event.type === 'DOMMouseScroll') {
            // This is a last resort. A lot less performant. 
            this.onScroll();
        }
    }

    // Desktop only functions
    private onScroll() {
        this.scrollState.deltaY = this.scrollState.target.scrollTop - this.previous.scrollTop;
        this.scrollState.scrollTop += this.scrollState.deltaY;
        this.deltaWasSet = true;
        
        this.startAnimationLoop();
    }

    private onWheel(event: WheelEvent) {
        const deltaMode = event.deltaMode;
        if (deltaMode === DeltaMode.Pixel || deltaMode === DeltaMode.Line) {
            event.preventDefault();
            
            // Calculates the deltaY based on the deltaMode. event.deltaY can represent either an amount of pixels
            // or a number of lines.
            // 
            // On Firefox for Linux (Ubuntu) the deltaMode is set to 1 (lines). This differs from all other browsers currently available
            // and messes up the scroll effect unless taken into account. Sadly, on Ubuntu this means that Firefox will only scroll in
            // 3 line increments as deltaY seems to only be -3, 0 or 3.
            this.scrollState.deltaY = deltaMode === DeltaMode.Pixel ? event.deltaY : event.deltaY * this.lineHeight;
            this.scrollState.scrollTop += this.scrollState.deltaY;
            this.deltaWasSet = true;

            this.startAnimationLoop();

        } else {
            this.onScroll();
        }
    }

    // Mobile only functions
    private readonly touchMoveHandler = this.touchMove.bind(this);
    private touchMove(event: TouchEvent) {
        event.preventDefault();

        const touchY = event.changedTouches[0].screenY;
        this.scrollState.deltaY = this.previous.touchY - touchY;
        this.previous.touchY = touchY;

        this.scrollState.scrollTop += this.scrollState.deltaY;

        this.deltaWasSet = true;
    }

    private touchStart(event: TouchEvent) {
        event.preventDefault();

        this.previous.touchY = event.touches[0].screenY;
        this.initialiseTouchScroll();
    }

    private readonly touchEndHandler = this.touchEnd.bind(this);
    private touchEnd(event: TouchEvent) {
        this.scrollState.deltaY = 0;
        this.deltaWasSet = false;
        this.removeTouchScroll();
    }

    private initialiseTouchScroll() {
        this.touch = true;
        this.scrollState.target.addEventListener('touchmove', this.touchMoveHandler);
        this.scrollState.target.addEventListener('touchend', this.touchEndHandler);
        this.scrollState.target.addEventListener('touchcancel', this.touchEndHandler);

        // Starts the requestAnimationFrame loop, stops automatically when both deltaYWasSet and 
        // touch are false. This is taken care of by the touchEndHandler and the removeTouchScroll functions
        // respectively.
        this.requestAnimationFrame();
    }

    private removeTouchScroll() {
        this.touch = false;
        this.scrollState.target.removeEventListener('touchmove', this.touchMoveHandler);
        this.scrollState.target.removeEventListener('touchend', this.touchEndHandler);
        this.scrollState.target.removeEventListener('touchcancel', this.touchEndHandler);
    }

    public cleanup() {
        this.removeTouchScroll();
        this.stop();
        this.animationCleanup();

        // To remove reference to object:
        // scrollController = scrollController.cleanup()
        return null;
    }
}

export default ScrollController;