import { AnimationState } from "../models/ScrollState";
import { remToPixels, pixelsToRem, getLineHeight } from "../../functions/typography";
import { ScrollAnimation } from "../models/ScrollAnimation";

class TerminalScroll implements ScrollAnimation {
    private lineHeight: number;


    public onUpdate(animationState: AnimationState) {
        // Sets the scrollTop value to the nearest multiple of lineHeight.
        animationState.scrollTop = remToPixels(Math.ceil(pixelsToRem(animationState.scrollTop, this.lineHeight) - 0.5), this.lineHeight);
    }

    constructor(target: HTMLElement) {
        this.lineHeight = getLineHeight(target);
        if (!(target.scrollHeight % this.lineHeight)) {
            console.warn("DiscreteRemScroll: the scrollHeight of the target HTML element is not evenly divisible by the lineHeight of the element. Discrete scrolling might not work properly.")
        }
    }
}

export default TerminalScroll;