export interface IScrollState {
    target: HTMLElement;
    deltaY: number;
    scrollTop: number;
    scrollLeft: number;
}

export class ScrollState implements IScrollState {
    protected _scrollTop: number;
    public readonly target: HTMLElement;
    public deltaY: number;

    public get scrollTop(): number {
        return this._scrollTop;
    }

    public set scrollTop(value: number) {
        this._scrollTop = Math.max(Math.min(value, this.target.scrollHeight - this.target.offsetHeight), 0);
    }

    public scrollLeft: number;

    constructor(scrollState: IScrollState) {
        this.target = scrollState.target;
        this.deltaY = scrollState.deltaY;
        this._scrollTop = scrollState.scrollTop;
        this.scrollLeft = scrollState.scrollLeft;
    }
}

export class AnimationState extends ScrollState {

    setTo(scrollState: IScrollState) {
        this.deltaY = scrollState.deltaY;
        // _scrollTop is set directly as this is significantly more performant.
        // Necessary as this function gets called frequently.
        this._scrollTop = scrollState.scrollTop;
        this.scrollLeft = scrollState.scrollLeft;
    }
}