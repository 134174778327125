import { HTMLAttributes, useEffect } from "react";
import React from "react";
import './TerminalWindow.scss'
import { setHeightRem, setRealvh } from "../functions/typography";
import ManProgress from "./ManProgress";
import ScrollController from "../scrollController/controllers/ScrollController";
import TerminalScroll from "../scrollController/animations/TerminalScroll";
  

interface TerminalWindowProps extends HTMLAttributes<HTMLDivElement> {
    loaded: boolean;
}

const TerminalWindow: React.FC<TerminalWindowProps> = ({loaded, children}) => {
    useEffect(() => {
        const centeringContainer = document.querySelector('.centeringContainer') as HTMLElement | null;
        const terminalWindow = document.querySelector('.terminalWindow') as HTMLElement | null;
        const terminalContent = document.querySelector('.terminalContent') as HTMLElement | null;
        let cleanup: Array<Function> = new Array<Function>();

        if (centeringContainer) {
            setRealvh(centeringContainer);
            window.addEventListener('resize', () => setRealvh(centeringContainer));
        }

        if (terminalWindow && terminalContent) {
            setHeightRem([terminalWindow]);

            window.addEventListener('resize', () => setHeightRem([terminalWindow]));
            cleanup.push(() => window.removeEventListener('resize', () => setHeightRem([terminalWindow])));

            let scrollController: ScrollController | null | undefined = new ScrollController(terminalContent);

            scrollController
                .setFps(60)
                .addAnimation(TerminalScroll)
                .start();
                
            cleanup.push(() => scrollController = scrollController?.cleanup());
        }

        return () => {for (let fn of cleanup) {fn()}}
    }, [])

    return (
        <div className='centeringContainer'>
            <div className='terminalWindow'>
                <div className='terminalContent'>
                        {children}
                </div>
                <div className='terminalFooter'>
                    {loaded &&
                        <ManProgress target={document.querySelector('.terminalContent') as HTMLElement}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default TerminalWindow;