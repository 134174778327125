import { HTMLAttributes } from "react";
import React from "react";
import './ManHeader.scss';

interface ManHeaderProps extends HTMLAttributes<HTMLDivElement> {
    command: string;
}

const ManHeader: React.FC<ManHeaderProps> = ({command, ...rest}) => {
    return (
        <div className='manHeader' {...rest}>
            <div>{command.toUpperCase() + '(1)'}</div>
            <div>User Commands</div>
            <div>{command.toUpperCase() + '(1)'}</div>
        </div>
    )
}

export default ManHeader;