import React, { useState } from 'react';
import './App.scss';
import SplashScreen from './pages/SplashScreen';
import ManPage from './pages/ManPage';
import TerminalWindow from './components/TerminalWindow';

function App() {
  const [loaded, setLoaded] = useState(false);

  return (
        <TerminalWindow loaded={loaded}>
        {loaded ?
                  <ManPage /> :
                  <SplashScreen setLoaded={setLoaded} />
        }
        </TerminalWindow> 
  );
}

export default App;
